.container {
  position: relative;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 200px;
  }
}

.cardActions {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 0;
  height: 50px;
}

.card {
  background-color: rgb(255, 255, 255);
  border-radius: 0.375rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card a {
  text-decoration: none;
  color: black;
}

.card a:hover {
  color: black;
}

.card:hover {
  transform: scale(1.02);
}

.cardContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  backdrop-filter: blur(5px);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.cardImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
