.container {
  position: relative;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 200px;
  }
}

.card {
  background-color: rgb(255, 255, 255);
  border-radius: 0.375rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.card a {
  text-decoration: none;
  color: black;
}

.card a:hover {
  color: black;
}

.card:hover {
  transform: scale(1.02);
}

.cardStatus {
  padding-right: 5px;
}
