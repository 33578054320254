.disabledBtn {
  background-color: transparent;
}

.item {
  margin-bottom: 5px;
  border-radius: 15px;
  height: auto;
}

.item div {
  width: 100%;
  text-align: center;
}

.item p {
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: smaller;
}

.itemTitle {
  font-weight: 600;
  width: 150px;
  font-size: large;
  text-transform: capitalize;
}

.itemFirstText {
  text-transform: capitalize;
}

.container {
  margin-bottom: 50px;
}
