.container {
  position: relative;
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 200px;
  }
}

.selectedOrdersViewOption {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.toggleButtonGroup {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100% !important;
  height: 2.3rem;
}

.toggleButton {
  width: 30% !important;
}

@media (max-width: 768px) {
  .toggleButton {
    width: 50% !important;
  }
}
