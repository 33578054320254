@media (max-width: 768px) {
    .formWrapper {
        display: flex;
        gap: 2rem;
        align-items: center;
    }
    .select {
        width: 50%;
    }
}

@media (min-width: 769px) {
    .formWrapper {
        width: 60%;
        display: flex;
        gap: 2rem;
        align-items: center;
    }
}

