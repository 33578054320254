.container {
  position: relative;
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 200px;
  }
}

.orderCard {
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
}

.orderCard a {
  text-decoration: none;
  color: black;
}

.orderCard a:hover {
  color: black;
}

.activeOrder {
  background: rgb(0, 126, 25);
  background: linear-gradient(
    180deg,
    rgba(0, 126, 25, 1) 1%,
    rgba(255, 255, 255, 1) 1%,
    rgba(255, 255, 255, 1) 100%
  );
  color: black;
}

.inactiveOrder {
  background: rgb(131, 131, 131);
  background: linear-gradient(
    180deg,
    rgb(131, 131, 131) 1%,
    rgba(255, 255, 255, 1) 1%,
    rgba(255, 255, 255, 1) 100%
  );
  color: black;
}

.freeTableBtn {
  width: 100%;
  margin-top: 0.3rem;
  background-color: rgb(255, 166, 0);
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s;
}

.freeTableBtn:hover {
  background-color: rgb(255, 189, 67);
}

.products {
  height: 123px;
  overflow-y: auto;
  scrollbar-color: var(--secondary-color) #fff2e2;
  scrollbar-width: thin;
  width: auto;
  border-bottom: 1px solid var(--navbar-color);
}

.product {
  margin-top: 5px;
  padding-bottom: 3px;
  border-bottom: 1px dotted rgb(207, 207, 207);
  width: 95%;
}

.waiters {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--navbar-color);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.orderTime {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--navbar-color);
}

.totalPrice {
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 500;
}

.orderedProduct img {
  padding-right: 5px;
  height: auto;
  width: 1rem;
}

.orderedProduct {
  display: flex;
  flex-direction: row;
}

.productName {
  margin: 0;
  font-size: 14px;
}

.productQuantity {
  margin: 0;
  font-size: 14px;
  align-items: center;
  display: flex;
}

.productInfo {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
}

.comment {
  font-size: 12px;
  margin-top: -5px;
  padding-left: 15px;
  margin: 0;
}

.orderTimeText {
  margin: 0;
  font-size: 14px;
}

.showMoreBtn {
  margin: 0;
  padding: 0;
}

.showMoreText {
  margin: 0;
  font-size: 0.6rem;
}

.primaryWaiter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.orderHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 2rem;
}

.orderViewModeBtn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -55%);
}

.createdTime {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.closedTime {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.selectedOrdersViewOption {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.toggleButtonGroup {
  display: flex;
  justify-content: center;
  width: 100% !important;
  height: 2.3rem;
}

.toggleButton {
  width: 30% !important;
}

@media (max-width: 768px) {
  .toggleButton {
    width: 50% !important;
  }
}
