.container {
  position: relative;
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 200px;
  }
}

.particularProductContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.productInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productName {
  font-weight: 600;
  font-size: 25px;
}

.productPrice {
  font-weight: 600;
  font-size: 20px;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.commentInput {
  width: 300px;
  margin: auto;
}

.productCard {
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 1.5rem;
  width: 350px;
  height: auto;
}

.productCard img {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.productCard a {
  text-decoration: none;
  color: black;
}

.productCard a:hover {
  color: black;
}

.cardActions {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px 0;
}

.productDescription {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 0.5rem;
  max-height: 8rem;
  overflow-y: auto;
}
