.footer {
    padding-top: 15px;
    width: 100%;
    height: 50px;
    /* background-color: #eef3f8; */
    background-color: #2a2d30;
    /* background-color: #2c2c2c; */
    margin-top: auto;
}

.container {
    display: flex;
    justify-content: space-between;
}

.icon{
    height: 24px;
}

@media (max-width: 768px) {
    .footer{
        margin-bottom: 40px;
    }
}

.text {
    /* color: rgba(0, 0, 0, 0.6); */
    color:rgb(212, 212, 212);
    font-size: 12px;
    display: inline;
}