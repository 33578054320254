.searchContainer {
  display: flex;
  margin: 0;
  padding: 0;
}

.disabledContainer {
  opacity: 0.6;
}

.searchContainer img {
  position: absolute;
  height: 16px;
  margin-left: 15px;
  margin-top: 11px;
}

@media (min-width: 1200px) {
  .searchContainer {
    margin-left: 50px;
  }
}

.cancelBtn {
  color: white;
  position: absolute;
  margin: 0;
  padding: 0;
  margin-left: 12px;
  margin-top: 9px;
}

.cancelBtn svg {
  height: 20px;
}

input[type='search'] {
  padding-left: 35px;
  margin-left: 5px;
  height: 36px;
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  box-sizing: border-box;
  background-color: #3f3f3f;
  color: white;
  font-family: inherit;
}

input[type='search']::placeholder {
  color: rgb(146, 146, 146);
}

input[type='search']:focus {
  border: none;
  box-shadow: none;
  background-color: #5a5a5a;
  color: white;
  outline: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.searchResultsContainer {
  position: absolute;
  background-color: white;
  width: 40dvw;
  color: black;
  border-radius: 0.25rem;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1;
  margin-top: 40px;
  padding: 0;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
  max-width: 100%;
}

.searchResult {
  width: 100%;
  display: inline-block;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchResult:hover {
  background-color: rgb(233, 233, 233);
  color: rgb(0, 0, 0);
}

.selectContainer {
  margin-left: 5px;
}

.container {
  display: flex;
  flex-direction: row;
}

.link {
  text-decoration: none;
  color: black;
}
