.productCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgb(250, 250, 250);
  border-radius: 0.375rem;
  border: 1px solid rgb(207, 207, 207);
  min-height: 2.6rem;
}

.productInfoContainer {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
}

.productInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.productName {
  font-weight: 500;
  font-size: 1rem;
  padding-left: 0.3rem;
}

.quantity {
  font-weight: 400;
  font-size: 1rem;
}

.quantitySpan {
  font-weight: 400;
  font-size: 1rem;
  padding-left: 0.3rem;
}

.commentContainer {
  width: 100%;
  height: auto;
}

.comment {
  font-weight: 400;
  font-size: 0.875rem;
}

.btn {
  background-color: var(--secondary-color);
  color: white;
  width: 15%;
  border-radius: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.btn:hover {
  background-color: #e99700 !important;
}

.statusContainer {
  background-color: var(--secondary-color);
  width: 10%;
  min-height: 2.5rem;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.statusOnHold {
  background-color: #ce0000;
}

.statusUnderPreparation {
  background-color: #ffc400;
}

.statusDone {
  background-color: #008006;
}

.statusDelivered {
  background-color: #8a8a8a;
}
