.pagination {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1.2rem;
}

.btn {
  background-color: white;
  border: none;
  border-radius: 0.25rem;
  color: var(--secondary-color);
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem;
}

.btn:hover {
  background-color: white;
  color: var(--secondary-color);
}

.btn:focus {
  background-color: white;
  color: var(--secondary-color);
}

.btnSelected {
  background-color: var(--secondary-color);
  color: white !important;
}

@media (max-width: 768px) {
  .pagination {
    margin-bottom: 4rem;
  }
}
