.toast {
  margin: 0;
  padding: 0.5rem;
  background-color: rgb(255, 255, 255);
  display: flex;
  border-top: 4px solid;
  flex-direction: row;
  align-items: center;
  max-width: 19rem;
  min-height: 3.5rem;
  height: auto;
  opacity: 1;
}

@media (max-width: 768px) {
  .toast {
    margin-bottom: 2rem;
  }
}

.bordersuccess {
  border-color: #3e8635;
}

.bordererror {
  border-color: #c9190b;
}

.borderinfo {
  border-color: #2b9af3;
}

.text {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 500;
}

.textsuccess {
  color: #1e4f18;
}

.texterror {
  color: #7d1007;
}

.textinfo {
  color: #004368;
}

.content {
  margin: 0;
  padding: 0;
  margin-left: 0.3rem;
  display: flex;
  flex-direction: column;
}

.toastIcon {
  margin: 0;
  padding: 0;
  height: auto;
  width: 1.3rem;
}

.iconsuccess {
  color: #3e8635;
}

.iconerror {
  color: #c9190b;
}

.iconinfo {
  color: #2b9af3;
}

.button {
  margin-left: auto;
  color: #6a6e73;
}
