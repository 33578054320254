.container {
  position: relative;
}

.item {
  margin: 0;
  margin-bottom: 1px;
  border-bottom: 1px dotted rgb(207, 207, 207);
  height: auto;
  /* display:flex ;
    justify-content:space-between;
    flex-direction: row; */
  align-items: center;
  background-color: white;
  padding: 7px 0;
}

.numericUpDown {
  display: flex;
  justify-content: center;
}

.listGroup {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px 0;
  padding: 8px;
  border-radius: 8px;
}

.itemTitle {
  display: flex;
  /* width: 150px; */
  font-weight: 500;
  font-size: medium;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
}

.itemComment {
  font-weight: 400;
  height: auto;
  font-size: small;
  width: 100%;
  height: 40px;
  overflow: auto;
  font-size: 0.75rem;
  color: rgb(121, 121, 121);
  word-wrap: break-word;
  white-space: normal;
}

.itemTotalDetails {
  margin: 0;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 7px 0;
}

.itemTotal {
  display: flex;
  width: 120px;
  font-weight: 600;
  font-size: larger;
  justify-content: center;
  text-transform: capitalize;
}

.itemPrice {
  display: flex;
  width: 100px;
  font-weight: 500;
  font-size: large;
  text-transform: capitalize;
  justify-content: center;
}

.mainInfo {
  /* width: 150px; */
  display: flex;
  flex-direction: column;
  margin: 0;
}

.itemQuantity {
  text-transform: capitalize;
}

.btn {
  display: flex;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 0.9rem;
  width: 380px;
  margin: auto;
}

.clearAll {
  color: white;
}

.collapse {
  border-radius: 50%;
  color: white;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--navbar-color);
}

.leftHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rightHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tableNumber {
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.4rem;
}

.icon {
  width: 24px;
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 200px;
  }

  .btn {
    width: 100%;
  }
}
