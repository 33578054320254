.btn {
  display: inline-block;
  margin-top: 0.3rem;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s;
  width: 15rem;
}

.addBtn {
  background-color: rgb(0, 168, 34);
}

.users {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 1rem auto;
  padding-left: 0;
}

.users li {
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  padding: 1rem 2rem;
  border-radius: var(--radius);
  text-align: left;
}

.users img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.users h5 {
  color: grey;
  text-transform: capitalize;
  font-size: small;
}

.users a {
  text-decoration: none;
  color: black;
}

.users a:hover {
  color: black;
}
