.content {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 2.5rem;
}

.container {
  height: 100dvh;
}

.topHalf {
  background-color: var(--secondary-color);
  position: absolute;
  height: 100%;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 30%, 0 60%);
  z-index: 1;
}

.bottomHalf {
  background-color: var(--primary-color);
  position: absolute;
  height: 100%;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  z-index: 0;
}

.installPWAContainer {
  display: flex;
  z-index: 2;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 600px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 1rem;
  z-index: 3;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  gap: 1rem;
  margin-top: 4rem;
}

.input fieldset {
  border: none;
  outline: none;
}

.input {
  background-color: rgb(240, 240, 240);
  border-radius: 0.6rem;
}

.input input {
  padding: 12px 14px 12px 0;
}

.logoImage {
  font-size: 0px;
  margin-top: -80px;
}

.logoImage a img {
  width: 300px;
}

.loginError {
  text-align: center;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: -35px;
  font-size: 0.7em;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 5px;
  padding: 10px;
  width: 60%;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.btnLoading {
  width: 94%;
  margin-top: -3px;
  height: 3px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

@media (max-width: 768px) {
  .form {
    width: 350px;
    height: 500px;
  }

  .nameInput {
    width: 45%;
    height: 30px;
  }

  .passwordInput {
    width: 45%;
    height: 30px;
  }

  .submitInput {
    height: 30px;
  }

  .logoImage {
    transform: scale(0.7);
  }
}
