.container {
  position: relative;
  margin-bottom: 4rem;
}

.orderCard {
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 1.5rem;
}

.orderCard a {
  text-decoration: none;
  color: black;
}

.orderCard a:hover {
  color: black;
}

.product_OnHold {
  background: rgb(214, 19, 19);
  background: linear-gradient(
    180deg,
    rgb(214, 19, 19, 1) 2%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: black;
}

.product_UnderPreparation {
  background: rgb(214, 163, 19);
  background: linear-gradient(
    180deg,
    rgba(214, 163, 19, 1) 2%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: rgb(0, 0, 0);
}

.product_Done {
  background: rgb(19, 214, 19);
  background: linear-gradient(
    180deg,
    rgba(19, 214, 19, 1) 2%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: rgb(0, 0, 0);
}

.product_Delivered {
  background: rgb(109, 109, 109);
  background: linear-gradient(
    180deg,
    rgb(109, 109, 109) 2%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: rgb(0, 0, 0);
}

.kitchenProductButton_OnHold {
  width: 100%;
  background-color: rgb(255, 166, 0);
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  transition: color 0.15s;
  border-radius: 0;
}

.kitchenProductButton_OnHold:hover {
  background-color: rgb(255, 189, 67);
}

.kitchenProductButton_UnderPreparation {
  width: 100%;
  background-color: rgb(255, 166, 0);
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  transition: color 0.15s;
  border-radius: 0;
}

.kitchenProductButton_UnderPreparation:hover {
  background-color: rgb(19, 214, 19);
}

.kitchenProductButton_Done {
  width: 100%;
  background-color: rgb(255, 166, 0);
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  transition: color 0.15s;
  border-radius: 0;
}

.kitchenProductButton_Done:hover {
  background-color: rgb(214, 19, 19);
}

.order {
  background-color: rgb(255, 255, 255);
  border-radius: 0.375rem;
  box-shadow: rgba(168, 168, 168, 0.35) 0px 5px 15px;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  margin-top: 1.5rem;
  width: 100%;
  height: 100%;
}

.tableContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
  justify-content: center;
}

.tableText {
  font-size: 1.5rem;
  font-weight: 600;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--navbar-color);
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.infoText {
  font-size: 16px;
  font-weight: 500;
}

.waiter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  margin-right: 2px;
}

.products {
  overflow-y: auto;
  height: 100%;
  max-height: 68vh;
}

.product {
  padding-bottom: 0.4rem;
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 200px;
  }
  .products {
    height: 63vh;
  }
}
