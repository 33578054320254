.navbar {
  background-color: var(--navbar-color);
  position: sticky;
  top: 0;
  padding: 0;
  flex-wrap: nowrap;
}

.toolbar {
  margin: 0;
  padding: 0;
  min-height: 51px;
  height: 51px;
  justify-content: space-between;
}

.downOnMobile {
  background-color: var(--navbar-color);
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.downOnMobile a {
  margin-right: 20px;
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .downOnMobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    justify-content: center;
    align-items: center;
    height: 55px;
    /* padding-top: 8px;
        padding-bottom: 8px; */
  }

  .logoutContainer span {
    display: none;
  }
}

.logoutContainer a {
  margin-left: 15px;
  display: flex;
  flex-wrap: nowrap;
}

.dot {
  height: 18px;
  width: 18px;
  background-color: var(--secondary-color);
  /* background-color: #FFA94D; */
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: -7px;
  margin-top: -4px;
}

.search {
  display: flex;
  align-items: center;
}
