.productsHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 2rem;
  background-color: white;
  border-radius: 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  color: #000000;
}

.nameButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 0;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  border-right: 1px solid rgba(0, 0, 0, 0.175);
}

.text {
  font-weight: 500;
  font-size: 1rem;
}

.lastPressed {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.categoryButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 0;
  /* border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem; */
  border-right: 1px solid rgba(0, 0, 0, 0.175);
}

.priceButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 0;
  /* border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem; */
  border-right: 1px solid rgba(0, 0, 0, 0.175);
}

.filterButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 100%;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 0;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.icon {
  margin-right: 0.2rem;
  width: 20px;
}

.productsHeaderContainer {
  background-color: white;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  border-radius: 1.25rem;
}

.filtersContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.autocomplete {
  width: 16rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.autocomplete input {
  height: 1.25rem;
}

.autocomplete fieldset {
  outline: none;
  border-color: rgba(0, 0, 0, 0.175);
  border-radius: 1.25rem;
}
