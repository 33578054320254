.container{
    font-weight: 400;
    display:inline-flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items: center;
    height: 100%;
    width: fit-content !important;
}
.numberInput{
    margin: 0;
    padding: 0;
}

.numberInput input{
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    background: #fff;
    color: #1C2025;
    border: 1px solid #DAE2ED;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 0 5px;
    padding: 10px 12px;
    outline: 0;
    width: 3rem;
    text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.numberInput fieldset{
    border: none;
}

.numberInput:hover {
    border-color: var(--primary-color);
}

.numberInput:focus {
    border-color: var(--primary-color);
}

.iconBtn{
    padding: 0;
}

@media (max-width: 768px) {
    .numberInput input {
        width: 2rem;
    }
}