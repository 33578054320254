.select {
  margin: 0;
  height: 36px;
  width: 65px;
  border: none;
  border-radius: 0.25rem;
  box-sizing: border-box;
  background-color: #3f3f3f;
  color: white;
  outline: none;
}

.select:hover {
  border: none;
  box-shadow: none;
  background-color: #5a5a5a;
  color: white;
  outline: none;
}

.select:focus {
  border: none;
  box-shadow: none;
  background-color: #5a5a5a;
  color: white;
  outline: none;
}

.select input {
  color: white;
  padding: 0 14px;
  height: 36px;
}

.select fieldset {
  border: none;
  box-shadow: none;
  outline: none;
}

.select svg {
  color: white;
}

.container {
  display: flex;
  flex-direction: row;
}

.btn {
  color: white;
  position: absolute;
  margin: 0;
  padding: 0;
  margin-left: 35px;
  margin-top: 8px;
}

.btn svg {
  height: 20px;
}

.resultsContainer {
  position: absolute;
  background-color: white;
  width: 40dvw;
  color: black;
  border-radius: 0.25rem;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1;
  margin-top: 40px;
  padding: 0;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
  max-width: 100%;
}

.result {
  width: 100%;
  display: inline-block;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result:hover {
  background-color: rgb(233, 233, 233);
  color: rgb(0, 0, 0);
}
