.stacked {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
}

.btn {
    margin-top: 0.5rem;
    color: #ffffff;
    width: 14rem;
}

.btnLoading {
    width: 14rem;
    margin-top: -3px;
    height: 3px;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}