.imagePreview {
    object-fit: cover;
    width: 350px;
    height: 200px;
    border: 2px dashed rgb(212, 207, 207);
    border-radius: 8px;
    margin-top: 0.5rem;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imagePreviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgb(212, 207, 207);
    border-radius: 8px;
    background-color: var(--background);
    height: 200px;
    width: 350px;
    margin-top: 0.5rem;
    color: rgb(160, 160, 160);
}

.previewIcon {
    width: 45%;
    height: 70%;
}

.uploadBtn { 
    margin-top: 0.5rem;
    color: #ffffff;
    margin-bottom: 0.5rem;
}

@media (max-width: 1200px) {
    .imagePreviewContainer {
        width: 100%;
    }
    .imagePreview {
        width: 100%;
    }
}

